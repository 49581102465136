:root {
  /** BOOSTRAP OVERRIDES */
  --bs-light: #fafafa;
  --bs-info: var(--secondary-color-contrast);
  --bs-primary: var(--primary-color);
  --bs-primary-rgb: 231, 227, 208;
  --bs-body-font-family: 'Open Sans', sans-serif;
}

/** Style overriding */
.bg-primary {
  color: var(--primary-color-contrast) !important;
  background-color: var(--primary-color) !important;
}

[ngbDatepickerDayView],
.ngb-dp-day,
.ngb-dp-weekday,
.ngb-dp-week-number {
  width: 2rem !important;
  height: 2rem !important;
  @media screen and (min-width: 330px) {
    width: 3.5rem !important;
    height: 3rem !important;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--bs-info) !important;
}

ngb-datepicker-navigation-select {
  gap: 10px !important;
}

[ngbDatepickerNavigationSelect], select {
  color: black !important;
  border-radius: var(--border-radius) !important;
}

ngb-datepicker-navigation {
  margin-bottom: 20px;
}

.ngb-dp-navigation-chevron {
  border-color: var(--primary-color) !important;
}

.btn.btn-disabled, .btn[disabled], .btn:disabled {
  display:none;
}

.ngb-dp-weekday {
  font-style: normal !important;
  font-weight: 500 !important;
}
