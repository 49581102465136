@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500&display=swap');

/*
 * Global application theme.
 * Framework overrides and customization goes here.
 */

* {
  box-sizing: border-box;
}

:root {
  /** Colors */
  --primary-color: #171717;

  --primary-color-lighter: #414141;
  --primary-color-dark: #f5ecd0;
  --primary-color-contrast: #f1f1f1;
  --secondary-color: #e6e3d2;
  --secondary-color-contrast: #1d1c11;


  --border-radius: '3px';

  --background-color: white;
  --container-color: white;
  --box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;

  --border-color: #e4e4e7;
  --font-color: #2d2d2d;
  --secondary-font-color: #3f403b;
  --error-color: #c70000;

  --secondary-border-color:  rgb(209,213,219);

  --radio-backogrund-color: #fafafa;
  --radio-border-color: var(--primary-color-contrast);
  --radio-color: black;
  --radio-hover-background-color: var(--primary-color);
  --radio-hover-color: var(--primary-color-contrast);

  --radio-checked-background-color: var(--primary-color);
  --radio-checked-border-color: var(--primary-color);
  --radio-checked-color: var(--primary-color-contrast);


  --sub-heading-color: #808080;

  /** Margins, sizes, etc. */
  --margin-small: 4px;
  --margin-standard: 24px;
  --margin-medium: 35px;
  --margin-large: 55px;
  --input-height: 42px;

  --positive-color: #bfb;
  --positive-color-dark: #4c4;


}

html,
body {
  font-family: "Poppins", sans-serif;
  display: flex;
  color: var(--font-color);
  flex-direction: column;
  margin: 0;
  height: 100%;
  min-height: 100vh;
}

* {
  font-family: inherit;
}

h1 {
  font-size: 28px;
  font-weight: 500;
}
