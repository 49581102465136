@import "theme/helpers.css";
@import "theme/bootstrapCalendarOverride.css";
@import "theme/radios.css";
@import "theme/theme.css";
@import "theme/dynamicElements.css";
@import "@angular/cdk/overlay-prebuilt.css";

@tailwind base;
@tailwind components;
@tailwind utilities;


ngx-material-intl-tel-input, .cdk-overlay-connected-position-bounding-box {

    .contains-mat-select-search, .tel-mat-select-pane, .mat-mdc-tooltip-panel, .tel-form {
      background-color: white !important;
    }
  
    .mat-mdc-form-field-infix {
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }
  
    .country-option, .mdc-text-field__input {
      font-size: 16px !important;
      font-weight: 300 !important;
    }
  
    .mdc-floating-label, mat-hint, .mat-mdc-tooltip-panel, .main-label {
      display: none !important;
    }
  
  }


