
/*
  Disse variablene settes opp her for å overstyre daisy når folk bruker widgetconfiguratoren
 */
.btn-primary {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;

  color: var(--primary-color-contrast) !important;

  border-radius: var(--border-radius) !important;
}

.checkbox {
  width: 35px !important;
  height: 35px !important;
}

.checkbox:checked {
  background-image: linear-gradient(-45deg, transparent 65%, var(--primary-color) 65.99%), linear-gradient(45deg, transparent 75%, var(--primary-color) 75.99%), linear-gradient(-45deg, var(--primary-color) 40%, transparent 40.99%), linear-gradient(45deg, var(--primary-color) 30%, var(--primary-color-contrast) 30.99%, var(--primary-color-contrast) 40%, transparent 40.99%), linear-gradient(-45deg, var(--primary-color-contrast) 50%, var(--primary-color) 50.99%) !important;
}

.checkbox-primary {
  border-color: var(--primary-color) !important;
  background-color: white !important;
}

.container-background-color {
  background-color: var(--container-color) !important;
}

.dynamic-border-radius {
  border-radius: var(--border-radius) !important;
}

.roundedPrimaryButton {
  background-color: var(--primary-color) !important;
  color: var(--primary-color-contrast) !important;
}

[ngbDatepickerDayView].active {
  color: white !important;
}

input {
  color: black !important;
}

.btn-active {
  background-color: var(--primary-color)!important;
  color: var(--primary-color-contrast)!important;
}

.btn-outline:not(.btn-active) {
  background-color: var(--background-color) !important;
}
