

.radioButtons input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;

}


.radioButtons label {
  display: inline-block;
  border-radius: var(--border-radius);

  padding: 0;

  width: 30px;
  height: 30px;
  font-size: 12px;
  @media screen and (min-width: 320px) {
    width: 60px;
    height: 60px;
    font-size: 18px;
  }

  font-weight: 400;
  border: 1px solid var(--radio-border-color);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .3s;
  &:hover:not(:disabled) {
    background-color: var(--radio-hover-background-color);
    color: var(--radio-hover-color);
    cursor: pointer;
    transition: .3s;
  }

  /*dette er i praksis kun i preview i app*/
  @media (max-height: 400px) {
    width: 28px;
    height: 28px;
    font-size: 10px;
  }
}

.radioButtons input[type="radio"]:checked + label {
  background-color:var(--radio-checked-background-color);
  border-color: var(--radio-checked-border-color);
  color: var(--radio-checked-color);
}


.radioButtons input[type="radio"]:focus + label {
  outline: 2px solid blue;
  outline-offset: 2px;
  background-color: var(--radio-hover-background-color);
}
